import FloatingButton from '@components/floatingButton/FloatingButton'
import { CartIcon } from '@icons/CartIcon'
import {
  CheckoutBoticarioURLConfig,
  MimoId,
  Product,
  ProductVariant,
} from 'Types'
import { t } from 'i18next'
import { useObservableCallback, useSubscription } from 'observable-hooks'
import { CheckoutProviderContext } from 'providers/CheckoutProvider'
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { catchError, mapTo, of, switchMap, tap } from 'rxjs'
import { useActionLoop, useSelector } from 'store/Store'
import { checkoutReducer } from 'store/checkoutReducer'
import DefaultModal from '../../components/defaultModal/DefaultModal'
import { useToaster } from '../../components/toaster/Toaster'
import { API_VERSION } from '../../consts'
import { HTTPError, jsonFetch } from '../../http/http'
import { useModal } from '../../modal'
import { CheckoutFinishedModal } from '../../modals/checkoutFinishedModal/CheckoutFinishedModal'
import { useMimoRouter } from '../../router/MimoRouter'
import { PATHS } from '../../router/Paths'
import {
  getCartCount,
  getCartItemsForTracking,
  getCartTotal,
} from '../../utils/CartUtils'
import { generateURL } from '../../utils/FormatUtil'
import { useConfig } from '../ConfigProvider'
import { useEmbedParams } from '../EmbedParamsProvider'
import { useTracking } from '../TrackingProvider'

type FinishCartPayload = {
  variants: { id: MimoId; quantity: number }[]
}

export function CheckoutBoticarioURLProvider({
  config,
  children,
}: PropsWithChildren<{
  config: CheckoutBoticarioURLConfig
}>) {
  const dispatch = useDispatch()

  const router = useMimoRouter()

  const [showToaster] = useToaster()

  const intentAddToCart = useActionLoop(
    checkoutReducer.actions.intent_addToCart,
    (_action) => {
      dispatch(checkoutReducer.actions.addToCart(_action.payload))
      showToaster(t('product_added_to_cart'))
      router.go(PATHS.home)
    }
  )

  const cart = useSelector((state) => state.checkout.cart)

  const addToCart = useCallback(
    (product: Product, variant: ProductVariant, quantity: number) => {
      intentAddToCart({
        product,
        quantity,
        variant,
      })
    },
    [intentAddToCart]
  )

  const editCart = useCallback(
    (product: Product, variant: ProductVariant, quantity: number) => {
      dispatch(
        checkoutReducer.actions.editCart({
          product,
          variant,
          quantity,
        })
      )
    },
    [dispatch]
  )

  const cartCount = useMemo(() => getCartCount(cart), [cart])

  const renderCartButton = useCallback(() => {
    return (
      <FloatingButton
        color="primary"
        onClick={() => {
          tracking.track({
            event: 'view_cart',
            currency: currency,
            value: cartTotal,
            items: cartTrackingItems,
          })
          router.go(PATHS.cart)
        }}
        circled={true}
        count={cartCount > 0 ? cartCount : undefined}
        icon={<CartIcon className="text-text" />}
        ariaLabel={t('cart')}
      />
    )
  }, [router, cartCount])

  const params = useEmbedParams()
  const tracking = useTracking()
  const currency = useConfig().settings?.currency?.code
  const cartTotal = useMemo(() => {
    return getCartTotal(cart)
  }, [cart])
  const cartTrackingItems = useMemo(() => {
    return getCartItemsForTracking(cart, currency)
  }, [cart, currency])

  const [isFinishing, setIsFinishing] = useState(false)

  // POST - send finish cart post
  const [finishCartPost, finishCartPost$] = useObservableCallback<
    string,
    FinishCartPayload
  >(($evt) =>
    $evt.pipe(
      switchMap((payload) => {
        setIsFinishing(true)
        return jsonFetch<{ success: boolean; redirect: string }>(
          generateURL(config.meta.checkout.endpoint, {
            MIMO_LIVE_ID: params.liveId!,
            MIMO_API_VERSION: API_VERSION,
          }),
          {
            method: 'POST',
            // Definimos o header 'Content-Type: application/json' para que o backend consiga processar o payload JSON, pois para evitar requisições preflight mudamos para 'text/plain', mas com essa mudança, o backend não lê o JSON como JSON.
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          }
        ).pipe(
          tap((res) => {
            dispatch(checkoutReducer.actions.resetCart())

            const opener = window.open(res.redirect)
            if (params.dataCheckoutModalOff == 'false') {
              const message = !opener
                ? t('text_error_new_tab')
                : t('order_completed')

              successModal.open({
                redirect: res.redirect,
                message: message,
              })
            }

            router.go(PATHS.home)
          }),
          catchError((err: HTTPError) => {
            console.error(err)
            showToaster(err.message || t('unable_to_add_to_cart'))

            return of('ok')
          }),
          tap(() => {
            setIsFinishing(false)
          }),
          mapTo('ok')
        )
      })
    )
  )

  useSubscription(finishCartPost$)

  const successModal = useModal<{ redirect: string; message: string }>({
    component: (
      <CheckoutFinishedModalWithMeta
        // meta will be sent by modal.open
        meta={{ redirect: '', message: '' }}
      />
    ),
    open: false,
  })

  const finishCart = useCallback(() => {
    const formData = {
      variants: cart.map((v) => ({
        id: v.variant.id,
        quantity: v.quantity,
      })),
    }
    finishCartPost(formData)
    tracking.track({
      event: 'purchase',
      currency: currency,
      value: cartTotal,
      transaction_id: 0,
      items: cartTrackingItems,
    })
  }, [cart, finishCartPost])

  const contextValue = useMemo(() => {
    return {
      isFinishing,
      finishCart,
      renderCartButton,
      type: config.type,
      addToCart,
      isAddingToCart: false,
      allowQuantityControl: true,
      getStockInfo: () => ({ available: true, quantityAvailable: true }),
      cart,
      editCart,
      cartCount,
    }
  }, [
    config,
    addToCart,
    finishCart,
    renderCartButton,
    cart,
    editCart,
    cartCount,
    isFinishing,
  ])

  return (
    <CheckoutProviderContext.Provider value={contextValue}>
      {successModal.elem}
      {children}
    </CheckoutProviderContext.Provider>
  )
}

function CheckoutFinishedModalWithMeta(props: {
  meta: { message: string; redirect?: string }
}) {
  return (
    <DefaultModal title={t('order_confirmed')}>
      <CheckoutFinishedModal
        text={props.meta.message}
        contact={
          props.meta.redirect
            ? {
                label: t('open_again'),
                target: '_blank',
                url: props.meta.redirect,
              }
            : undefined
        }
      ></CheckoutFinishedModal>
    </DefaultModal>
  )
}

CheckoutFinishedModal
